// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-en-us-js": () => import("./../src/pages/about.en-US.js" /* webpackChunkName: "component---src-pages-about-en-us-js" */),
  "component---src-pages-about-es-js": () => import("./../src/pages/about.es.js" /* webpackChunkName: "component---src-pages-about-es-js" */),
  "component---src-pages-glossary-en-us-js": () => import("./../src/pages/glossary.en-US.js" /* webpackChunkName: "component---src-pages-glossary-en-us-js" */),
  "component---src-pages-glossary-es-js": () => import("./../src/pages/glossary.es.js" /* webpackChunkName: "component---src-pages-glossary-es-js" */),
  "component---src-pages-index-en-us-js": () => import("./../src/pages/index.en-US.js" /* webpackChunkName: "component---src-pages-index-en-us-js" */),
  "component---src-pages-index-es-js": () => import("./../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-topics-en-us-js": () => import("./../src/pages/topics.en-US.js" /* webpackChunkName: "component---src-pages-topics-en-us-js" */),
  "component---src-pages-topics-es-js": () => import("./../src/pages/topics.es.js" /* webpackChunkName: "component---src-pages-topics-es-js" */),
  "component---src-templates-term-js": () => import("./../src/templates/term.js" /* webpackChunkName: "component---src-templates-term-js" */),
  "component---src-templates-tip-js": () => import("./../src/templates/tip.js" /* webpackChunkName: "component---src-templates-tip-js" */),
  "component---src-templates-topic-js": () => import("./../src/templates/topic.js" /* webpackChunkName: "component---src-templates-topic-js" */)
}

